<template>
  <TTView>
    <VRow>
      <VCol>
        <IndicatorCard :indicator="indicator" />
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import IndicatorCard from '../../../components/expert-assessment/indicators/IndicatorCard.vue';

export default {
  name: 'IndicatorsView',

  components: {
    IndicatorCard,
  },

  data() {
    return {
      loading: false,
      indicator: {
        id: null,
        name: null,
        description: '',
        weight: 0,
        skillAssessmentId: null,
      },
    };
  },

  computed: {
    skillAssessmentId() {
      return this.$route.params.assessmentId;
    },

    indicatorId() {
      return this.$route.params.indicatorId;
    },
  },

  async created() {
    await this.fetch();
  },

  methods: {
    async fetch() {
      try {
        this.loading = true;

        const { indicatorId } = this;
        const data = { id: indicatorId };
        const { indicator } = await this.$di.api.ExpertAssessment.indicatorsGet({ data });

        this.indicator = indicator;
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
